import { useState, useEffect } from "react";
import LineChart from "../../charts/LineChart01";
import Icon from "../../images/icon-01.svg";
// import EditMenu from "../EditMenu";
import { GiFarmer } from "react-icons/gi";
import { GiPlantRoots } from "react-icons/gi";
import { HiOutlineUsers } from "react-icons/hi";

// Import utilities
import { tailwindConfig, hexToRGB } from "../../utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../../utils/Utils";
import useBreakpoint from "../../utils/useBreakpoint";
import { useSelector } from "react-redux";

function Stat1() {
  const [farmData, setFarmData] = useState([]);
  const [plotData, setPlotData] = useState([]);
  const [farmersData, setFarmersData] = useState([]);
  const [usersData, setUsersData] = useState([]);

  const { isMobile, isTable } = useBreakpoint();

  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);

  const getFarmData = async () => {
    const endpoint = `${baseUrl}/metrics/getTotalFarmLand`;
    const token = localStorage.getItem("token");
    try {
      let response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        setFarmData(response?.data);
      }
    } catch (error) {
      if (isLoggedIn) {
        toast.error(`${error.message}`);
      }
    }
  };

  const getAllPlot = async () => {
    const endpoint = `${baseUrl}/metrics/getAllPlots`;
    const token = localStorage.getItem("token");
    try {
      let response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        setPlotData(response?.data);
      }
    } catch (error) {
      if (isLoggedIn) {
        toast.error(`${error.message}`);
      }
    }
  };

  const getAllFarmers = async () => {
    const endpoint = `${baseUrl}/metrics/getTotalFarmers`;
    const token = localStorage.getItem("token");
    try {
      let response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        setFarmersData(response?.data);
      }
    } catch (error) {
      if (isLoggedIn) {
        toast.error(`${error.message}`);
      }
    }
  };

  const getAllUsers = async () => {
    const endpoint = `${baseUrl}/metrics/getTotalUsers`;
    const token = localStorage.getItem("token");
    try {
      let response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        setUsersData(response?.data);
      }
    } catch (error) {
      if (isLoggedIn) {
        toast.error(`${error.message}`);
      }
    }
  };

  useEffect(() => {
    getFarmData();
    getAllPlot();
    getAllFarmers();
    getAllUsers();
  }, []);


  return (
    <div
      style={
        isMobile
          ? { width: "100%", marginTop: "20px" }
          : { width: "300px", marginTop: "20px" }
      }
      className="bg-white shadow-lg mb-5 rounded-3xl border border-slate-200"
    >
      <div className="px-5 pt-5">
        <header className="flex justify-between items-start mb-2">
         
        </header>
        <h2
          className={
            isMobile
              ? "text-lg text-center mt-5 font-semibold text-gray-500 text-slate-800 mb-2"
              : "text-lg mt-5 font-semibold text-gray-500 text-slate-800 mb-2"
          }
        >
          Total farmers registered
        </h2>
      
        <div
          className={
            isMobile ? "flex items-center justify-center" : "flex items-start"
          }
        >
          <div className="text-4xl flex font-bold text-slate-800 mr-2">
            <GiFarmer
              style={{
                backgroundImage:
                  "linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)",
                width: "45px",
                height: "45px",
                padding: "6px",
                borderRadius: "15px",
              }}
              className="text-green-800"
            />{" "}
            <span className="ml-6">
              {farmersData?.data?.total_farmers
                ? farmersData?.data?.total_farmers
                : "0"}
            </span>
            <span className="text-lg mt-4 ml-1">farmers</span>
          </div>
        </div>
        <h2
          className={
            isMobile
              ? "text-lg text-center mt-5 font-semibold text-gray-500 text-slate-800 mb-2"
              : "text-lg mt-5 font-semibold text-gray-500 text-slate-800 mb-2"
          }
        >
          Total Farm Land
        </h2>
        <div
          className={
            isMobile ? "flex items-center justify-center" : "flex items-start"
          }
        >
          <div className="text-4xl flex font-bold text-slate-800 mr-2">
            <GiPlantRoots
              style={{
                backgroundImage:
                  "linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)",
                width: "45px",
                height: "45px",
                padding: "6px",
                borderRadius: "15px",
              }}
              className="text-green-800"
            />{" "}
            <span className="ml-5">
              {farmData?.data?.totalFarmLand
                ? farmData?.data?.totalFarmLand
                : "0"}{" "}
            </span>
            <span className="text-lg mt-4 ml-1">acres</span>
          </div>
        </div>
        <h2
          className={
            isMobile
              ? "text-lg text-center mt-5 font-semibold text-gray-500 text-slate-800 mb-2"
              : "text-lg mt-5 font-semibold text-gray-500 text-slate-800 mb-2"
          }
        >
          Total users registered
        </h2>
        <div
          className={
            isMobile ? "flex items-center justify-center" : "flex items-start"
          }
        >
          <div className="text-4xl mb-5 flex font-bold text-slate-800 mr-2">
            <HiOutlineUsers
              style={{
                backgroundImage:
                  "linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)",
                width: "45px",
                height: "45px",
                padding: "6px",
                borderRadius: "15px",
              }}
              className="text-green-800"
            />{" "}
            <span className="ml-5">
              {usersData?.data?.total_users
                ? usersData?.data?.total_users
                : "0"}
            </span>
            <span className="text-lg mt-4 ml-1">users</span>
          </div>
        </div>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="grow">
        {/* Change the height attribute to adjust the chart height */}
        {/* <LineChart data={chartData} width={389} height={128} /> */}
      </div>
    </div>
  );
}

export default Stat1;
