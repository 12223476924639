
import React from "react";
import { useNavigate } from "react-router-dom";

function FieldTable({ fieldData }) {
  const navigate = useNavigate();

  // Helper to find duplicates
  const getDuplicates = (data) => {
    const seen = new Map();
    const duplicates = new Set();

    data.forEach((field) => {
      const key = `${field?.location?.lat},${field?.location?.lang}`;
      if (seen.has(key)) {
        duplicates.add(key);
      } else {
        seen.set(key, field);
      }
    });

    return duplicates;
  };

  const duplicates = getDuplicates(fieldData || []);

  return (
    <>
      {fieldData?.[0] ? (
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm">
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Tag</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">
                    Location coordinates
                  </div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Area</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Soil</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Gat Number</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm font-medium divide-y divide-slate-100">
              {fieldData?.map((data) => {
                const key = `${data?.location?.lat},${data?.location?.lang}`;
                const isDuplicate = duplicates.has(key);

                return (
                  <tr
                    key={data?._id}
                    style={{
                      cursor: "pointer",
                      backgroundColor: isDuplicate ? "#ffcccc" : "inherit",
                    }}
                    onClick={() => navigate(`/field/${data?._id}`)}
                  >
                    <td className="p-2">
                      <div className="flex items-center">
                        <div className="d-block">
                          <div className="text-slate-800">{data?.name}</div>
                          {isDuplicate && (
                            <div className="text-sm text-red-500">
                              This entry is duplicated
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="flex items-center">
                        <div className="d-block">
                          <div className="text-slate-800">
                            {data?.tag ? data?.tag : "N/A"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="d-block">
                        <div className="text-center">
                          Longitude - {data?.location?.lang || "N/A"}
                          <div className="">
                            Latitude - {data?.location?.lat || "N/A"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="d-block">
                        <div className="text-center">
                          {data?.area ? `${data?.area} acres` : "N/A"}
                        </div>
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="text-center">{data?.soil || "N/A"}</div>
                    </td>
                    <td className="p-2">
                      <div className="text-center">{data?.gatNo || "N/A"}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default FieldTable;
