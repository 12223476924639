import { useEffect, useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { baseUrl } from "../../utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Transition } from "@headlessui/react";
import useBreakpoint from "../../utils/useBreakpoint";

const INITIAL_VALUES = {
  name: "",
  tag: "",
  location: {
    lang: "",
    lat: "",
  },
  plantationDate: "",
  plantationArea: null,
  harvestDate: "",
  yield: "",
  estimatedYield: null,
  cropId: "",
  varietyId: "",
  soilType: "",
  isActive: "",
  growingSeason: "",
  centroid: null,
  plantCount: null,
  r2rDistance: null,
  p2pDistance: null,
  growingCycle: null,
  area: "",
};

function PlotModal({
  userData,
  setModalOpen,
  modalOpen,
  setSelectedPlot,
  selectedPlot,
  setRefetch,
  farmData,
  fieldData,
}) {
  const PlotValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    tag: Yup.string().required("Tag is required"),
    location: Yup.object().shape({
      lang: Yup.number()
        .typeError("Longitude must be a number")
        .required("Longitude is required"),
      lat: Yup.number()
        .typeError("Latitude must be a number")
        .required("Latitude is required"),
    }),
    plantationDate: Yup.date()
      .required("Plantation date is required")
      .typeError("Invalid date"),
    plantationArea: Yup.number()
      .required("Plantation area is required")
      .min(0, "Plantation area must be positive"),
    harvestDate: Yup.date()
      .required("Harvest date is required")
      .min(
        Yup.ref("plantationDate"),
        "Harvest date cannot be earlier than Plantation date"
      )
      .typeError("Invalid date"),
    yield: Yup.number()
      .required("Yield is required")
      .min(0, "Yield must be positive"),
    estimatedYield: Yup.number()
      .nullable()
      .typeError("Estimated Yield must be a number"),
    growingSeason: Yup.string().required("Growing Season is required"),
    cropId: Yup.string().required("Crop selection is required"),
    varietyId: Yup.string().required("Variety selection is required"),
    soilType: Yup.string().required("Soil type is required"),
    isActive: Yup.string().required("Active status is required"),
    centroid: Yup.number().nullable().typeError("Centroid must be a number"),
    plantCount: Yup.number()
      .nullable()
      .typeError("Plant count must be a number"),
    r2rDistance: Yup.number()
      .nullable()
      .typeError("Row-to-row distance must be a number"),
    p2pDistance: Yup.number()
      .nullable()
      .typeError("Plant-to-plant distance must be a number"),
    growingCycle: Yup.number()
      .nullable()
      .typeError("Growing cycle must be a number"),
    area: Yup.number()
      .required("Area is required")
      .min(0, "Area must be positive"),
  });
  const farmId = localStorage.getItem("currentFarmId");
  const modalContent = useRef(null);
  const [values, setValues] = useState(INITIAL_VALUES);
  const [showCrop, setShowCrop] = useState(false);
  const [showVariety, setShowVariety] = useState(false);
  const [showAddBtn, setShowAddBtn] = useState(true);
  const [showAddBtn2, setShowAddBtn2] = useState(true);
  const [cropData, setCropData] = useState([]);
  const [cropId, setCropId] = useState(null);
  const [varietyData, setvarietyData] = useState([]);
  const [crop, setCrop] = useState("");
  const [variety, setVariety] = useState("");
  const { isMobile } = useBreakpoint();

  const handlecropInput = (e) => {
    setCrop(e.target.value);
  };
  const handleVarietyInput = (e) => {
    setVariety(e.target.value);
  };

  const createCrop = async () => {
    const token = localStorage.getItem("token");
    const endpoint = `${baseUrl}/crop/`;
    let values = {
      name: crop,
    };
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          let response = await axios.post(endpoint, values, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            resolve(response);
            setShowCrop(false);
          }
        } catch (error) {
          reject(error);
          console.log(error);
        }
      }),
      {
        pending: "Creating crop...",
        success: "Crop Added Successfully 👍",
        error: "Error creating crop 🤯",
      }
    );
  };

  const createVariety = async () => {
    const token = localStorage.getItem("token");
    const endpoint = `${baseUrl}/variety/`;
    let values = {
      name: variety,
      cropId: "",
    };
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          let response = await axios.post(endpoint, values, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            resolve(response);
            setShowCrop(false);
          }
        } catch (error) {
          reject(error);
          console.log(error);
        }
      }),
      {
        pending: "Creating Variety...",
        success: "Variety Added Successfully 👍",
        error: "Error creating Variety 🤯",
      }
    );
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const token = localStorage.getItem("token");
    const formattedValues = {
      ...values,
      growingSeason: [values.growingSeason],
      centroid: [values.centroid],
    };

    const finalValues = {
      ...formattedValues,
      farmerId: userData?.[0]?._id,
      farmId,
      fieldId: fieldData?.[0]?._id,
    };

    const addMessage = selectedPlot
      ? "Updating plot details..."
      : "Adding plot details...";
    const successMessage = selectedPlot
      ? "Plot details updated successfully 👍"
      : "Plot details added successfully 👍";
    const errorMessage = selectedPlot
      ? "Error updating plot details 🤯"
      : "Error adding plot details 🤯";

    try {
      const endpoint = selectedPlot
        ? `${baseUrl}/plot/${selectedPlot._id}`
        : `${baseUrl}/plot/`;
      const method = selectedPlot ? "patch" : "post";
      const response = await axios[method](endpoint, finalValues, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        toast.success(successMessage);
        setModalOpen(false);
        setSelectedPlot(null);
        setRefetch(true);
        resetForm();
      }
    } catch (error) {
      toast.error(errorMessage);
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
      setSelectedPlot(null);
      setValues(INITIAL_VALUES);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // useEffect(() => {
  //   if (selectedPlot) {
  //     const formatted = {
  //       name: selectedPlot?.name,
  //       tag: selectedPlot?.tag,
  //       location: {
  //         lang: selectedPlot?.location?.lang,
  //         lat: selectedPlot?.location?.lat,
  //       },
  //       plantationDate: selectedPlot?.plantationDate,
  //       plantationArea: selectedPlot?.plantationArea,
  //       harvestDate: selectedPlot?.harvestDate,
  //       yield: selectedPlot?.yield,
  //       estimatedYield: selectedPlot?.estimatedYield,
  //       cropId: selectedPlot?.cropId,
  //       varietyId: selectedPlot?.varietyId,
  //       soilType: selectedPlot?.soilType,
  //       isActive: selectedPlot?.isActive,
  //       centroid: selectedPlot?.centroid,
  //       plantCount: selectedPlot?.plantCount,
  //       r2rDistance: selectedPlot?.r2rDistance,
  //       p2pDistance: selectedPlot?.p2pDistance,
  //       growingCycle: selectedPlot?.growingCycle,
  //       area: selectedPlot?.area,
  //     };
  //     setValues(formatted);
  //   }
  // }, [selectedPlot]);

  useEffect(() => {
    if (selectedPlot) {
      const formatted = {
        name: selectedPlot.name || "",
        tag: selectedPlot.tag || "",
        location: {
          lang: selectedPlot.location?.lang || "",
          lat: selectedPlot.location?.lat || "",
        },
        plantationDate: selectedPlot.plantationDate || "",
        plantationArea: selectedPlot.plantationArea || null,
        harvestDate: selectedPlot.harvestDate || "",
        estimatedYield: selectedPlot.estimatedYield || null,
        yield: selectedPlot.yield || null,
        cropId: selectedPlot.cropId || "",
        varietyId: selectedPlot.varietyId || "",
        soilType: selectedPlot.soilType || "",
        isActive: selectedPlot.isActive ? "true" : "false",
        growingSeason: selectedPlot.growingSeason?.[0] || "",
        centroid: selectedPlot.centroid?.[0] || null,
        plantCount: selectedPlot.plantCount || null,
        r2rDistance: selectedPlot.r2rDistance || null,
        p2pDistance: selectedPlot.p2pDistance || null,
        growingCycle: selectedPlot.growingCycle || null,
        area: selectedPlot.area || null,
      };
      setValues(formatted);
    }
  }, [selectedPlot]);

  const getCropData = async () => {
    const endpoint = `${baseUrl}/crop/getAllCropsWithoutPagination`;
    const token = localStorage.getItem("token");
    try {
      let response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        setCropData(response?.data);
        setRefetch(false);
      }
    } catch (error) {
      setRefetch(false);
      toast.error(`${error.message}`);
    }
  };

  const getVarietyData = async () => {
    const endpoint = `${baseUrl}/variety/getAllVarietiesWithoutPagination`;
    const token = localStorage.getItem("token");
    try {
      let response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        setvarietyData(response?.data);
        setRefetch(false);
      }
    } catch (error) {
      setRefetch(false);
      toast.error(`${error.message}`);
    }
  };

  useEffect(() => {
    getCropData();
  }, []);

  useEffect(() => {
    getVarietyData();
  }, [cropId]);

  return (
    <>
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className="bg-white overflow-auto max-w-6xl w-full max-h-full rounded shadow-lg"
        >
          <button
            style={{
              backgroundColor: "crimson",
              borderRadius: "50%",
              float: "right",
              width: "40px",
              height: "40px",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
            }}
            type="button"
            className="btn m-5"
            onClick={() => {
              setModalOpen(false);
              setSelectedPlot(null);
              setValues(INITIAL_VALUES);
            }}
          >
            X
          </button>
          <main className="flex-1">
            <div className="px-6 py-6">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 bg-white">
                <Formik
                  enableReinitialize
                  initialValues={values}
                  validationSchema={PlotValidationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue, isSubmitting, handleChange }) => (
                    <Form>
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        {/* Name */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium"
                          >
                            Name
                          </label>
                          <Field
                            name="name"
                            type="text"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        {/* Tag */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="tag"
                            className="block text-sm font-medium"
                          >
                            Tag
                          </label>
                          <Field
                            name="tag"
                            type="text"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="tag"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        {/* Location Fields */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="location.lang"
                            className="block text-sm font-medium"
                          >
                            Longitude
                          </label>
                          <Field
                            name="location.lang"
                            type="text"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="location.lang"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="location.lat"
                            className="block text-sm font-medium"
                          >
                            Latitude
                          </label>
                          <Field
                            name="location.lat"
                            type="text"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="location.lat"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        {/* Plantation Date */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="plantationDate"
                            className="block text-sm font-medium"
                          >
                            Plantation Date
                          </label>
                          <Field
                            name="plantationDate"
                            type="date"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="plantationDate"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        {/* Plantation Area */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="plantationArea"
                            className="block text-sm font-medium"
                          >
                            Plantation Area (acres)
                          </label>
                          <Field
                            name="plantationArea"
                            type="number"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="plantationArea"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        {/* Harvest Date */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="harvestDate"
                            className="block text-sm font-medium"
                          >
                            Estimated Harvest Date
                          </label>
                          <Field
                            name="harvestDate"
                            type="date"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="harvestDate"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        {/* Crop */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="cropId"
                            className="block text-sm font-medium"
                          >
                            Crop
                          </label>
                          <select
                            id="cropId"
                            name="cropId"
                            as="select"
                            value={values.cropId}
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                            onChange={(e) => {
                              // handleChange(e);
                              // setCropId(e.target.value);
                              handleChange(e);
                              const selectedCrop = cropData.find(
                                (crop) => crop._id === e.target.value
                              );
                              setCropId(e.target.value);
                              setFieldValue(
                                "yieldUnit",
                                selectedCrop?.yeildUnit || ""
                              );
                            }}
                          >
                            <option value="" disabled>
                              Select your option
                            </option>
                            {cropData?.map((crop) => (
                              <option key={crop?._id} value={crop?._id}>
                                {crop?.name}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage
                            name="cropId"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        {/* Variety */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="varietyId"
                            className="block text-sm font-medium"
                          >
                            Variety
                          </label>
                          <Field
                            name="varietyId"
                            as="select"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                            value={variety?._id}
                          >
                            <option value="" disabled>
                              Select your option
                            </option>
                            {varietyData?.map((variety) => (
                              <option key={variety?._id} value={variety?._id}>
                                {variety?.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="varietyId"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>
                        {/* Yield */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="yield"
                            className="block text-sm font-medium"
                          >
                            Yield ({values.yieldUnit || "unit"})
                          </label>
                          <Field
                            name="yield"
                            type="number"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="yield"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        {/* Estimated Yield */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="estimatedYield"
                            className="block text-sm font-medium"
                          >
                            Estimated Yield ({values.yieldUnit || "unit"})
                          </label>
                          <Field
                            name="estimatedYield"
                            type="number"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="estimatedYield"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        {/* Soil Type */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="soilType"
                            className="block text-sm font-medium"
                          >
                            Soil Type
                          </label>
                          <Field
                            name="soilType"
                            type="text"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="soilType"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="plantCount"
                            className="block text-sm font-medium"
                          >
                            Plant Count
                          </label>
                          <Field
                            name="plantCount"
                            type="number"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="plantCount"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="r2rDistance"
                            className="block text-sm font-medium"
                          >
                            Row-to-Row Distance (inches)
                          </label>
                          <Field
                            name="r2rDistance"
                            type="number"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="r2rDistance"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="p2pDistance"
                            className="block text-sm font-medium"
                          >
                            Plant-to-Plant Distance (inches)
                          </label>
                          <Field
                            name="p2pDistance"
                            type="number"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="p2pDistance"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="growingCycle"
                            className="block text-sm font-medium"
                          >
                            Growing Cycle (Days)
                          </label>
                          <Field
                            name="growingCycle"
                            type="number"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="growingCycle"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="growingSeason"
                            className="block text-sm font-medium"
                          >
                            Growing Season
                          </label>
                          <Field
                            name="growingSeason"
                            type="text"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="growingSeason"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="isActive"
                            className="block text-sm font-medium"
                          >
                            Active
                          </label>
                          <div>
                            <select
                              id="isActive"
                              name="isActive"
                              value={values.isActive}
                              onChange={handleChange}
                              className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                            >
                              <option value="" disabled selected>
                                Select your option
                              </option>
                              <option value="true">Active</option>
                              <option value="false">Inactive</option>
                            </select>
                          </div>
                          <ErrorMessage
                            name="isActive"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="centroid"
                            className="block text-sm font-medium"
                          >
                            Centroid
                          </label>
                          <Field
                            name="centroid"
                            type="number"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="centroid"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="area"
                            className="block text-sm font-medium"
                          >
                            Area
                          </label>
                          <Field
                            name="area"
                            type="number"
                            className="shadow-sm focus:ring-[#008542] focus:border-[#008542] block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="area"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>
                      </div>

                      <div className="pt-5 pb-4 flex justify-end">
                        <button
                          type="submit"
                          className="btn bg-[#00AB55] text-white"
                          disabled={isSubmitting}
                        >
                          {selectedPlot ? "Update details" : "Submit details"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </main>
        </div>
      </Transition>
    </>
  );
}

export default PlotModal;
