import { useState, useEffect } from "react";
import CropTable from "../components/crop/CropTable";
import VarietyTable from "../components/crop/VarietyTable";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl } from "../utils/Utils";
import useBreakpoint from "../utils/useBreakpoint";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

function Crop() {
  const [refetch, setRefetch] = useState(false);
  const [cropData, setCropData] = useState({});
  const [varietyData, setVarietyData] = useState({});
  const [cropPage, setCropPage] = useState(1);
  const [varietyPage, setVarietyPage] = useState(1);
  const { isMobile, isExtremeSmall } = useBreakpoint();
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const [cropDataWithoutPagination, setCropDataWithoutPagination] = useState(
    []
  );
  const getCropData = async () => {
    const endpoint = `${baseUrl}/crop/getAllCrops?page=${cropPage}`;
    const token = localStorage.getItem("token");
    try {
      let response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        setCropData(response?.data);
        setRefetch(false);
      }
    } catch (error) {
      setRefetch(false);
      if (isLoggedIn) {
        toast.error(`${error.message}`);
      }
    }
  };

  const getCropDataWithoutPagination = async () => {
    const endpoint = `${baseUrl}/crop/getAllCropsWithoutPagination`;
    const token = localStorage.getItem("token");
    try {
      let response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        setCropDataWithoutPagination(response?.data);
        setRefetch(false);
      }
    } catch (error) {
      setRefetch(false);
      if (isLoggedIn) {
        toast.error(`${error.message}`);
      }
    }
  };
  const getVarietyData = async () => {
    const endpoint = `${baseUrl}/variety/allVarieties?page=${varietyPage}`;
    const token = localStorage.getItem("token");
    try {
      let response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        setVarietyData(response?.data);
        setRefetch(false);
      }
    } catch (error) {
      setRefetch(false);
      if (isLoggedIn) {
        toast.error(`${error.message}`);
      }
    }
  };

  const handleCropPageClick = (selectedPage) => {
    setCropPage(selectedPage.selected + 1);
    setRefetch(true);
  };

  const handleVarietyPageClick = (selectedPage) => {
    setVarietyPage(selectedPage.selected + 1);
    setRefetch(true);
  };

  useEffect(() => {
    getCropData();
    getVarietyData();
    getCropDataWithoutPagination();
  }, []);

  useEffect(() => {
    if (refetch) {
      getCropData();
    }
  }, [cropPage, refetch]);

  useEffect(() => {
    if (refetch) {
      getVarietyData();
    }
  }, [varietyPage, refetch]);

  return (
    <main className="flex-1">
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl ml-5 mb-4 font-semibold text-gray-900">
            Manage Crops
          </h1>
        </div>
        <div className={isMobile ? "grid grid-cols-1" : "grid grid-cols-2"}>
          <div
            style={{ width: "-webkit-fill-available" }}
            className="max-w-5xl mx-auto px-4 sm:px-6 md:px-8"
          >
            <CropTable
              cropData={cropData?.data}
              setCropData={setCropData}
              setRefetch={setRefetch}
            />
            {cropData?.data?.length > 0 && (
              <div className="App mt-6">
                <ReactPaginate
                  previousLabel={
                    <span className="flex items-center">
                      {!isExtremeSmall && <AiOutlineArrowLeft />} &nbsp; Prev
                    </span>
                  }
                  nextLabel={
                    <span className="flex items-center">
                      Next &nbsp; {!isExtremeSmall && <AiOutlineArrowRight />}
                    </span>
                  }
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={cropData?.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handleCropPageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            )}
          </div>
          <div
            style={{ width: "-webkit-fill-available" }}
            className="max-w-5xl mx-auto px-4 sm:px-6 md:px-8"
          >
            <VarietyTable
              cropData={cropDataWithoutPagination}
              varietyData={varietyData?.data}
              setRefetch={setRefetch}
            />
            {varietyData?.data?.length > 0 && (
              <div className="App mt-6">
                <ReactPaginate
                  previousLabel={
                    <span className="flex items-center">
                      {!isExtremeSmall && <AiOutlineArrowLeft />} &nbsp; Prev
                    </span>
                  }
                  nextLabel={
                    <span className="flex items-center">
                      Next &nbsp; {!isExtremeSmall && <AiOutlineArrowRight />}
                    </span>
                  }
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={varietyData?.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handleVarietyPageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Crop;
